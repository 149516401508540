@mixin arrow-background-hover {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: $p1;
    border-radius: 50%;
  }

  &:active {
    pointer-events: none;
  }

  &:hover {
    path {
      fill: $white;
      stroke: $white;
    }

    &::before {
      animation: scale 0.3s ease-in-out forwards;
      width: 48px;
      height: 48px;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    z-index: -1;
  }
}
