@import 'styles/import.scss';

.inputWrapper {
  @include green-underline;
  height: fit-content;
  width: 335px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  button {
    display: none;
  }
  &.isFocus {
    &::after {
      background: $green_light;
    }
  }
  &:hover {
    :local(.search) {
      path {
        stroke: $green;
      }
    }
  }

  input {
    width: 287px;
    background-color: transparent;
    outline: 0;
    padding: 10px 0;
    cursor: text;
    caret-color: $green_light;
    @include input-placeholder {
      @include text-md;
      color: $grey50;
    }
  }
  @include media-breakpoint-up(md) {
    width: auto;
    @include make-col(3, 6);
    input {
      width: 292px;
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5, 12);
    margin-bottom: 48px;

    input {
      width: 331px;
    }
  }
  @include media-breakpoint-up(xl) {
    @include make-col(4, 12);
    input {
      width: 336px;
    }
  }
  @include media-breakpoint-up(xxl) {
    input {
      width: 379px;
    }
  }
}

.iconWrapper {
  display: flex;
}

.delete {
  cursor: pointer;
  margin-right: 8px;
  path {
    @include tn(fill, 0.5s, 0s, ease-in-out);
  }
  &:hover {
    path {
      fill: $grey100;
    }
  }
}
