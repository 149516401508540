@import 'styles/import.scss';

.wrapper {
  align-self: center;

  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($blue_dark, 0.95);
    opacity: 0;
    z-index: 101;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  &.formOpen:after {
    opacity: 1;
  }
}

.title {
  @include heading-3;
  padding-bottom: 16px;
}

.subtitle {
  @include text-md;
  padding-bottom: 64px;
}

.fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}
