@import 'styles/import.scss';

.searchWrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    padding-top: 80px;
    gap: 64px;
  }
}

.searchContainer {
  input {
    color: $white;
  }
  > div {
    margin: 0;
    width: 100%;
    max-width: initial;
    flex: 1;
    svg * {
      stroke: $white;
    }
  }
}

.hits {
  @include media-breakpoint-up(lg) {
    &:hover {
      li {
        opacity: 0.6;
      }
    }
  }
}
