@import 'styles/import.scss';

.menu {
  @include tn(transform opacity);

  @include media-breakpoint-down(md) {
    @include make-container;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
    opacity: 0;
    padding-top: 80px;
    padding-bottom: 80px;
    height: calc(100vh - #{$header-height-sm});

    &.active {
      @include tn(transform opacity);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transform: translateX(0);
    }

    &.last {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    opacity: 0;
    visibility: hidden;
    margin-left: 0;
    animation: fadeOut 0.5s;
    animation-fill-mode: forwards;
    display: none;
    margin: 0;
    max-height: calc(100vh - #{$header-height});
    padding-top: 80px;
    padding-bottom: 160px;

    &.active {
      display: block;
      visibility: visible;
      animation-fill-mode: forwards;
      animation: fadeIn 0.8s;
    }

    &:hover li {
      opacity: 0.6;
    }
  }

  &.active {
    @include hide-scrollbar;
    overflow-y: auto;
    opacity: 1;
  }
}

.arrowDown {
  cursor: pointer;
  position: absolute;
  bottom: 120px;
  right: 12px;
  background-image: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_4993_81034" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40"><rect width="40" height="40" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_4993_81034)"><path d="M20.0021 29.423L10.5791 20L12.3355 18.2436L18.7522 24.6603V8.75H21.2521V24.6603L27.6688 18.2436L29.4252 20L20.0021 29.423Z" fill="white"/></g></svg>');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  z-index: 10;
  @include media-breakpoint-up(lg) {
    bottom: 20px;
  }
}

.profile {
  display: flex;
  cursor: pointer;
  align-items: center;
  color: $white;
  gap: 8px;
  padding-bottom: 48px;
}

.legal {
  position: relative;
  color: white;

  a {
    @include text-xs;
    margin-bottom: 16px;
    display: block;
    color: white;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.back {
  height: 36px !important;
  width: 36px !important;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  path {
    fill: white;
  }
}

.searchWrapper {
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: $white;
  svg * {
    stroke: $white;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    display: block;
  }
}
